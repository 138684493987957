.container {
  display: flex;
  flex-direction: column;
  padding: 15px 50px;

  h3 {
    font: normal normal bold 20px/27px "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #000000;
    margin-bottom: 20px;
  }
  .assignAll {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 20px;
    cursor: pointer;
    width: fit-content;

    p {
      font-weight: bold;
    }
  }
  .manufacturers {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 20px;

    li {
      list-style-type: none;
      display: flex;
      align-items: center;
      gap: 5px;
      border: 1px solid #cfdbec;
      border-radius: 5px;
      padding: 3px 5px;
      cursor: pointer;
    }
  }
  button {
    width: 135px;
    height: 46px;
    background: #1a59da 0% 0% no-repeat padding-box;
    border-radius: 5px;
    border: none;
    font: normal normal normal 15px/20px "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #ffffff;
    align-self: flex-end;
    cursor: pointer;
  }
}
