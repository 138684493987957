.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .filterContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 20px 0px 40px 0px;

    select {
      width: 260px;
      height: 45px;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #95a5b2;
      border-radius: 5px;
      padding: 0px 20px;

      font: normal normal normal 15px/20px "Open Sans", sans-serif;
      letter-spacing: 0px;
      color: #000000;

      background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
        no-repeat;
      background-position: calc(100% - 14.8rem) center !important;
      -moz-appearance: none !important;
      -webkit-appearance: none !important;
      appearance: none !important;
      padding-left: 2rem !important;
    }
  }

  .emptyRow {
    td {
      text-align: center !important;
      padding: 100px 0px !important;
      font-size: 20px;
      font-weight: bold;
    }
  }

  .loadMore {
    margin-top: 10px;
    align-self: center;
    width: 140px;
    height: 45px;
    background: var(--secondary-color) 0% 0% no-repeat padding-box;
    border-radius: 5px;
    border: none;
    font: normal normal normal 15px/20px "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
  }
  .modalContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 30px 30px 30px 5px;

    h3 {
      font: normal normal normal 25px/34px "Open Sans", sans-serif;
      letter-spacing: 0px;
      color: #000000;
      margin-bottom: 20px;
    }
    .invalid {
      border: 1px solid red;
    }
    form {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      .field {
        display: flex;
        flex-direction: column;
        width: 230px;
        input,
        select {
          width: 100%;
          height: 45px;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #95a5b2;
          border-radius: 5px;
          padding: 0px 10px;
        }
        .error {
          color: red;
        }
      }
      button {
        width: 230px;
        height: 45px;
        background: var(--secondary-color) 0% 0% no-repeat padding-box;
        border-radius: 5px;
        font: normal normal normal 19px/26px "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: #ffffff;
        display: flex;
        align-items: center;
        gap: 15px;
        border: none;
        cursor: pointer;
        img {
          margin: 0px 15px 0px 25px;
          width: 22px;
          height: 21px;
        }
      }
    }
  }

  .modalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    h1 {
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 30px;
    }

    .btnContainer {
      display: flex;
      align-items: center;
      gap: 20px;

      button {
        width: 200px;
        height: 46px;
        border-radius: 5px;
        border: none;
        font: normal normal normal 15px/20px "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: #ffffff;
        align-self: flex-end;
        cursor: pointer;

        &:nth-child(1) {
          background: #1a59da 0% 0% no-repeat padding-box;
        }

        &:nth-child(2) {
          background: #d22b2b 0% 0% no-repeat padding-box;
        }
      }
    }
  }
}
