.container {
  padding: 20px 50px;
  font-family: "Open Sans", sans-serif;
  form {
    display: flex;
    flex-direction: column;

    textarea {
      //   width: 740px;
      width: 100%;
      height: 94px;
      border: 1px solid #95a5b2;
      border-radius: 5px;
      padding: 15px 20px;
      resize: none;
      margin-bottom: 15px;
      font-family: "Open Sans", sans-serif;
    }
    button {
      width: 135px;
      height: 46px;
      background: #1a59da 0% 0% no-repeat padding-box;
      border-radius: 5px;
      border: none;
      font: normal normal normal 15px/20px "Open Sans", sans-serif;
      letter-spacing: 0px;
      color: #ffffff;
      align-self: flex-end;
      cursor: pointer;
    }
  }

  .list {
    h5 {
      font: normal normal bold 15px/20px "Open Sans", sans-serif;
      letter-spacing: 0px;
      color: #000000;
      margin-bottom: 15px;
    }

    .comment {
      border-top: 1px solid #707070;
      padding: 10px 0px;
      font-family: "Open Sans", sans-serif;
      h6 {
        font: normal normal bold 15px/20px "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: #000000;
        margin-bottom: 5px;
      }
      p {
        font: normal normal normal 15px/20px "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: #000000;
      }
    }
  }
}
