.calendar {
  border: none;
  width: 210px;
  background: #e6e6e6 0% 0% no-repeat padding-box;
}
.react-calendar__navigation {
  border: 1px solid #000;
  background: #e6e6e6 0% 0% no-repeat padding-box;
  border: 1px solid #00000000;
  height: 30px;
  margin-bottom: 0px;
}
.react-calendar__navigation__arrow.react-calendar__navigation__next2-button,
.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__navigation__arrow.react-calendar__navigation__next-button,
.react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
}

.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  font: normal normal normal 13px/15px Arial;
  letter-spacing: 0px;
  color: #000000;
}

.react-calendar__month-view__weekdays {
  height: 30px;
  background: #ebebeb 0% 0% no-repeat padding-box;
  border: 1px solid #00000000;
}

.react-calendar__month-view__weekdays__weekday {
  font: normal normal normal 11px/12px Arial;
  letter-spacing: 0px;
  color: #000000;
}

.react-calendar__month-view__weekdays__weekday > abbr {
  text-decoration: none;
}

.react-calendar__month-view__days {
  background: #f2f2f2 0% 0% no-repeat padding-box;
}

.react-calendar__tile.react-calendar__month-view__days__day {
  font: normal normal normal 12px/14px Arial;
  letter-spacing: 0px;
  color: #000000;
}

.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend {
  background: #e6e6e6;
}
