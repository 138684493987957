.container {
  display: flex;
  align-items: center;
  gap: 15px;
  width: fit-content;
  input {
    width: 594px;
    height: 45px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #95a5b2;
    border-radius: 5px;
    padding: 0px 20px;

    font: normal normal normal 15px/20px "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #000000;

    transition: all 300ms ease-in-out;

    &:focus {
      border: 1px solid rgb(99, 109, 117);
    }
  }

  button {
    width: 72px;
    height: 45px;
    background: #1c58da 0% 0% no-repeat padding-box;
    border-radius: 5px;
    border: none;
    font: normal normal normal 15px/20px "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #ffffff;
    cursor: pointer;
  }
}
