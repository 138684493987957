.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font: normal normal bold 20px/27px "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #000000;
    margin-bottom: 30px;
  }

  .subContainer {
    display: flex;
    // justify-content: center;
    width: 100%;
    max-width: 730px;

    .separator {
      height: 435px;
      border: 1px solid #cfdbec;
      margin: 0px 40px;
    }
  }
}
