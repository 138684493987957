.header {
  height: 95px;
  width: 100%;
  background: var(--primary-color) 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: center;

  .wrapper {
    width: 100%;
    padding: 0px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .right {
      display: flex;
      align-items: center;
      .logo {
        width: fit-content;
        margin-left: 50px;
        img {
          width: 150px;
        }
      }
      nav {
        ul {
          display: flex;
          align-items: center;
          li {
            list-style: none;
            font: normal normal normal 15px/20px "Open Sans", sans-serif;
            letter-spacing: 0px;
            color: #ffffff;
            opacity: 1;
            margin-left: 40px;
            cursor: pointer;
            padding: 0px 10px;
            height: 70px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid var(--primary-color);
            transition: all 250ms ease-in-out;

            &:hover {
              border-bottom: 1px solid #fff;
            }
          }
          .active {
            border-bottom: 1px solid #fff;
            font-weight: 500;
          }
        }
      }
    }

    .left {
      .admin {
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;

        &:hover {
          .drop {
            display: block;
          }
        }

        img {
          border-radius: 50px;
          border: 1px solid #ffffff;
          margin-left: 10px;
        }
        p {
          font: normal normal normal 15px/20px "Open Sans", sans-serif;
          letter-spacing: 0px;
          color: #ffffff;
        }

        .drop {
          display: none;
          width: 120px;
          height: auto;
          border-radius: 5px;
          background-color: #ffffff;
          box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
            rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
          position: absolute;
          top: 27px;
          right: 0px;
          padding: 15px;

          ul {
            list-style-type: none;
            display: flex;
            flex-direction: column;

            li {
              display: flex;
              align-items: center;
              cursor: pointer;

              img {
                width: 20px;
              }
            }
          }

          &:hover {
            display: block;
          }
        }
      }
    }
  }
}
