.container {
  display: flex;
  justify-content: center;
  align-items: center;

  .wrapper {
    width: 100%;
    max-width: 849px;

    .dlt {
      margin-top: 20px;
      width: 100%;
      background: #D22B2B;
      border: none;
      height: 45px;
      border-radius: 5px;
      cursor: pointer;
      font: normal normal normal 15px/20px "Open Sans", sans-serif;
      letter-spacing: 0px;
      color: #ffffff;
    }
  }

  .modalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    h1 {
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 30px;
    }

    .btnContainer {
      display: flex;
      align-items: center;
      gap: 20px;
      button {
        width: 200px;
        height: 46px;
        border-radius: 5px;
        border: none;
        font: normal normal normal 15px/20px "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: #ffffff;
        align-self: flex-end;
        cursor: pointer;

        &:nth-child(1) {
          background: #1a59da 0% 0% no-repeat padding-box;
        }
        
        &:nth-child(2) {
          background: #D22B2B 0% 0% no-repeat padding-box;
        }
      }
    }
  }
}