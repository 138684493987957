.container {
  display: flex;
  justify-content: center;
  align-items: center;

  .wrapper {
    width: 100%;
    max-width: 849px;
    display: flex;
    flex-direction: column;

    h1 {
      text-align: center;
      margin-bottom: 40px;
    }

    form {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 30px;

      .field {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 20px;

        label {
          margin-bottom: 10px;
          font-size: 18px;
          font-weight: 600
        }

        input,
        textarea {
          height: 45px;
          border: 1px solid #95a5b2;
          border-radius: 5px;
          padding: 10px 10px;
        }

        textarea {
          height: 150px;
          resize: none;
          font-family: "Open Sans", sans-serif;
        }
      }

      button {
        width: 100%;
        height: 45px;
        background: var(--secondary-color) 0% 0% no-repeat padding-box;
        border-radius: 5px;
        border: none;
        cursor: pointer;
        font: normal normal normal 15px/20px "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: #ffffff;
      }
    }
  }
}
