.container {
  .modalContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 30px 30px 30px 5px;

    h3 {
      font: normal normal normal 25px/34px "Open Sans", sans-serif;
      letter-spacing: 0px;
      color: #000000;
      margin-bottom: 20px;
    }

    form {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      input {
        width: 230px;
        height: 45px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #95a5b2;
        border-radius: 5px;
        padding: 0px 10px;
      }
      .upload {
        width: 230px;
        height: 45px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #95a5b2;
        border-radius: 5px;
        padding: 0px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .file {
        position: absolute;
        opacity: 0;
      }
      .preview {
        width: 45px;
        height: 45px;
        border-radius: 5px;
      }
      button {
        width: 230px;
        height: 45px;
        background: var(--secondary-color) 0% 0% no-repeat padding-box;
        border-radius: 5px;
        font: normal normal normal 19px/26px "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: #ffffff;
        display: flex;
        align-items: center;
        gap: 15px;
        border: none;
        cursor: pointer;
        img {
          margin: 0px 15px 0px 25px;
          width: 22px;
          height: 21px;
        }
      }
    }
  }
}
