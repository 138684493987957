.container {
  .emptyRow {
    td {
      text-align: center !important;
      padding: 100px 0px !important;
      font-size: 20px;
      font-weight: bold;
    }
  }
  .modalContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 30px 30px 30px 5px;

    h3 {
      font: normal normal normal 25px/34px "Open Sans", sans-serif;
      letter-spacing: 0px;
      color: #000000;
      margin-bottom: 20px;
    }

    form {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 15px;

      .field {
        display: flex;
        flex-direction: column;
        width: 230px;
        input,
        select {
          width: 100%;
          height: 45px;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #95a5b2;
          border-radius: 5px;
          padding: 0px 10px;
        }
        .error {
          color: red;
        }
      }
      button {
        width: 230px;
        height: 45px;
        background: var(--secondary-color) 0% 0% no-repeat padding-box;
        border-radius: 5px;
        font: normal normal normal 19px/26px "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: #ffffff;
        display: flex;
        align-items: center;
        gap: 15px;
        border: none;
        cursor: pointer;
        img {
          margin: 0px 15px 0px 25px;
          width: 22px;
          height: 21px;
        }
      }
    }
  }

  .modalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    h1 {
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 30px;
    }

    .btnContainer {
      display: flex;
      align-items: center;
      gap: 20px;

      button {
        width: 200px;
        height: 46px;
        border-radius: 5px;
        border: none;
        font: normal normal normal 15px/20px "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: #ffffff;
        align-self: flex-end;
        cursor: pointer;

        &:nth-child(1) {
          background: #1a59da 0% 0% no-repeat padding-box;
        }

        &:nth-child(2) {
          background: #d22b2b 0% 0% no-repeat padding-box;
        }
      }
    }
  }
}
