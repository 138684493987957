.main {
  padding: 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 1200px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    .headerLeft {
      display: flex;
      align-items: center;
      gap: 20px;

      .filterContainer {
        position: relative;
        display: flex;
        align-items: center;
        gap: 10px;

        .filterButton {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 8px 16px;
          height: 40px;
          background: #ffffff;
          border: 1px solid #95a5b2;
          border-radius: 5px;
          font: normal normal normal 15px/20px "Open Sans", sans-serif;
          color: #000000;
          cursor: pointer;
          transition: all 0.2s ease-in-out;

          svg {
            color: #95a5b2;
            transition: all 0.2s ease-in-out;
          }

          &:hover {
            background: #f5f5f5;
            border-color: var(--secondary-color);
            
            svg {
              color: var(--secondary-color);
            }
          }

          &.active {
            background: var(--secondary-color);
            border-color: var(--secondary-color);
            color: white;

            svg {
              color: white;
            }
          }

          &.loading {
            opacity: 0.7;
            cursor: not-allowed;
          }
        }

        .loadingSpinner {
          position: absolute;
          right: -30px;
          width: 20px;
          height: 20px;
          border: 2px solid #f3f3f3;
          border-top: 2px solid var(--secondary-color);
          border-radius: 50%;
          animation: spin 1s linear infinite;
        }
      }
    }

    .btnContainer {
      display: flex;
      align-items: center;

      button {
        width: 119px;
        height: 35px;
        background: var(--secondary-color) 0% 0% no-repeat padding-box;
        border-radius: 5px;
        border: none;

        font: normal normal normal 15px/20px "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: #ffffff;

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        margin-left: 12px;
      }
    }
  }

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;

    thead {
      tr {
        th {
          font: normal normal bold 15px/20px "Open Sans", sans-serif;
          letter-spacing: 0px;
          color: #000000;
          text-align: right;

          &:last-child {
            // width: 150px;
          }
        }
      }
    }

    tbody {
      tr {
        background: #f6f6f6 0% 0% no-repeat padding-box;
        border-radius: 5px;

        td {
          padding: 10px 0px;
          text-align: right;

          font: normal normal normal 15px/20px "Open Sans", sans-serif;
          letter-spacing: 0px;
          color: #000000;

          &:first-child {
            padding: 0px 10px 0px 0px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }

          &:last-child {
            padding: 0px 0px 0px 20px;
            text-align: left;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;

            img {
              cursor: pointer;

              &:nth-child(2),
              &:nth-child(3) {
                margin-right: 20px;
              }
            }
          }
          button {
            width: 75px;
            height: 35px;
            background: var(--secondary-color) 0% 0% no-repeat padding-box;
            border-radius: 5px;
            border: none;
            font: normal normal normal 15px/20px "Open Sans", sans-serif;
            letter-spacing: 0px;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            cursor: pointer;
            &:disabled {
              opacity: 0.7;
              cursor: not-allowed;
            }
          }
          select {
            width: 320px;
            height: 45px;
            border: 1px solid #95a5b2;
            border-radius: 5px;
            padding: 0px 20px;

            font: normal normal normal 15px/20px "Open Sans", sans-serif;
            letter-spacing: 0px;
            color: #000000;

            background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
              no-repeat;
            background-color: #ffffff;
            background-position: calc(100% - 18.4rem) center !important;
            -moz-appearance: none !important;
            -webkit-appearance: none !important;
            appearance: none !important;
            padding-left: 2rem !important;
          }
          input {
            width: 320px;
            height: 45px;
            background: #ffffff 0% 0% no-repeat padding-box;
            border: 1px solid #95a5b2;
            border-radius: 5px;
            padding: 0px 20px;

            font: normal normal normal 15px/20px "Open Sans", sans-serif;
            letter-spacing: 0px;
            color: #000000;

            transition: all 300ms ease-in-out;

            &:focus {
              border: 1px solid rgb(99, 109, 117);
            }
          }
        }

        .statusTD {
          color: red !important;
        }

        &:nth-child(even) {
          background: #eaeef0 0% 0% no-repeat padding-box;
        }
      }
    }
  }

  .more {
    align-self: center;
    text-decoration: underline;
    font: normal normal bold 17px/23px "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: var(--secondary-color);
    cursor: pointer;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
