.container {
  padding: 15px 50px;

  h3 {
    font: normal normal bold 20px/27px "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #000000;
    margin-bottom: 20px;
  }

  form {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 20px;
    .radioField {
      width: 100%;
      margin-bottom: 8px;
      label {
        font: normal normal bold 15px/20px "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: #000000;
        margin-bottom: 15px;
      }
      .radioRow {
        margin-top: 10px;
        display: flex;
        align-items: center;
        gap: 15px;
        .radio {
          input {
            margin-left: 3px;
            transform: translateY(1px);
          }
        }
      }
    }
    .field {
      display: flex;
      flex-direction: column;

      label {
        font: normal normal bold 15px/20px "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: #000000;
        margin-bottom: 15px;
      }

      input,
      select {
        width: 230px;
        height: 45px;
        border: 1px solid #95a5b2;
        border-radius: 5px;
        padding: 0px 10px;
      }
    }

    .imgUpload {
      width: 102px;
      height: 79px;
      border: 1px dashed var(--secondary-color);
      border-radius: 5px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      input {
        position: absolute;
        width: 102px;
        height: 79px;
        opacity: 0;
        cursor: pointer;
      }

      p {
        font: normal normal normal 15px/20px "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: #000000;
      }
    }

    button {
      width: 220px;
      height: 45px;
      background: var(--secondary-color) 0% 0% no-repeat padding-box;
      border-radius: 5px;
      border: none;
      cursor: pointer;
      font: normal normal normal 15px/20px "Open Sans", sans-serif;
      letter-spacing: 0px;
      color: #ffffff;
    }
  }
}
