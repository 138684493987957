.container {
  width: fit-content;
  position: relative;
  input {
    width: 320px;
    height: 45px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #95a5b2;
    border-radius: 5px;
    padding: 0px 20px;

    font: normal normal normal 15px/20px "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #000000;

    transition: all 300ms ease-in-out;

    &:focus {
      border: 1px solid rgb(99, 109, 117);
    }
  }

  .remove {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: 700;
    cursor: pointer;
  }

  .dropdown {
    position: absolute;
    background-color: #ffffff;
    border: 1px solid #95a5b2;
    border-radius: 5px;
    border-top: none;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    width: 100%;
    height: 180px;
    overflow-y: auto;
    padding: 10px 20px;

    ul {
      display: flex;
      flex-direction: column;
      gap: 10px;
      li {
        list-style: none;
        width: fit-content;
        cursor: pointer;
      }
    }
  }
}
