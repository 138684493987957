.mainContainer {
  height: 100vh;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  .container {
    width: 100%;
    height: 100%;

    max-width: 855px;
    max-height: 257px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 10px;

    overflow-y: auto;
    padding: 20px 20px;
    margin: 0px 20px;
    display: flex;
    flex-direction: column;
    position: relative;

    .close {
      position: absolute;
      left: 20px;
      top: 10px;
      font: normal normal normal 25px/34px "Open Sans", sans-serif;
      letter-spacing: 0px;
      color: #000000;
      cursor: pointer;
    }
    .content {
      width: 100%;
      height: 100%;
    }
  }
}
