.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin-bottom: 30px;
  }

  .emptyRow {
    td {
      text-align: center !important;
      padding: 100px 0px !important;
      font-size: 20px;
      font-weight: bold;
    }
  }

  .loadMore {
    margin-top: 10px;
    align-self: center;
    width: 140px;
    height: 45px;
    background: var(--secondary-color) 0% 0% no-repeat padding-box;
    border-radius: 5px;
    border: none;
    font: normal normal normal 15px/20px "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
  }
}
