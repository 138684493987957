.container {
  padding: 15px 50px;
  h4 {
    margin-bottom: 15px;
  }
  h3 {
    font: normal normal bold 15px/20px "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #000000;
    margin-bottom: 10px;
  }

  table {
    border-collapse: separate;
    border-spacing: 0px 10px;
    margin-bottom: 10px;
    width: 45%;
    thead {
      tr {
        th {
          padding-left: 50px;
          text-align: right;
          font: normal normal bold 15px/20px "Open Sans", sans-serif;
          letter-spacing: 0px;
          color: #000000;
        }
      }
    }
    tbody {
      tr {
        td {
          text-align: right;
          font: normal normal normal 15px/20px "Open Sans", sans-serif;
          letter-spacing: 0px;
          color: #000000;
        }
      }
    }
  }

  .btn {
    width: 181px;
    height: 45px;
    background: var(--secondary-color) 0% 0% no-repeat padding-box;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font: normal normal normal 15px/20px "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #ffffff;
    margin-bottom: 20px;
  }

  .subscribe {
    display: flex;
    flex-direction: column;
    input {
      width: 210px;
      height: 40px;
      border: 1px solid #95a5b2;
      border-radius: 5px;
      padding: 0px 10px;
      margin: 20px 0px 15px 0px;
    }
    .btn {
      width: 210px;
      margin-bottom: 0px;
    }
  }
}
