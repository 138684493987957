.container {
  width: 91px;
  height: 23px;
  background: #fee0e7 0% 0% no-repeat padding-box;
  border-radius: 5px;
  font: normal normal normal 15px/20px "Open Sans", sans-serif;
  letter-spacing: 0px;
  color: #f7325c;
  display: flex;
  align-items: center;
  justify-content: center;
}
.green {
  color: #18C877;
  background: #CEF5E5 0% 0% no-repeat padding-box;
}
.red {
  color: #f7325c;
  background: #fee0e7 0% 0% no-repeat padding-box;
}