.container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 40px;

  input {
    width: 320px;
    height: 45px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #95a5b2;
    border-radius: 5px;
    padding: 0px 20px;

    font: normal normal normal 15px/20px "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #000000;

    transition: all 300ms ease-in-out;

    &:focus {
      border: 1px solid rgb(99, 109, 117);
    }
  }

  select {
    width: 320px;
    height: 45px;
    border: 1px solid #95a5b2;
    border-radius: 5px;
    padding: 0px 20px;

    font: normal normal normal 15px/20px "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #000000;

    background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
      no-repeat;
    background-color: #ffffff;
    background-position: calc(100% - 18.4rem) center !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    padding-left: 2rem !important;
  }

  button {
    align-self: center;
    width: 140px;
    height: 45px;
    background: var(--secondary-color) 0% 0% no-repeat padding-box;
    border-radius: 5px;
    border: none;
    font: normal normal normal 15px/20px "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
  }
}
