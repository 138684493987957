.main {
  width: 100%;
  display: flex;
  justify-content: center;
  .wrapper {
    width: 100%;
    max-width: calc(1468px + 10px);
    padding: 70px 10px;
  }
}
