@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

html,
body {
  font-family: "Open Sans", sans-serif;
  direction: rtl;
}

:root {
  --primary-color: #1c58da;
  --secondary-color: #1a59da;
}

input,
select,
button {
  outline: none;
}

button {
  transition: all 300ms ease-in-out;
  &:hover {
    opacity: 0.9;
  }
}

.tableDeleteIcon {
  width: 20px;
  margin-bottom: -1px;
}