.card {
  padding: 10px;
  display: flex;
  align-items: flex-start;

  img {
    margin-left: 15px;
  }

  .text {
    h6 {
      font: normal normal bold 17px/23px "Open Sans", sans-serif;
      letter-spacing: 0px;
      color: #434c5a;
      margin-bottom: 5px;
    }

    h3 {
      font: normal normal bold 42px/57px "Open Sans", sans-serif;
      letter-spacing: 0px;
      color: #434c5a;
      margin-bottom: 10px;
    }
    p {
      text-decoration: underline;
      font: normal normal normal 15px/20px "Open Sans", sans-serif;
      letter-spacing: 0px;
      color: #434c5a;
      cursor: pointer;
    }
  }
}
