.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 100vh;
  padding: 40px 10px;

  .logo {
    margin-bottom: 50px;
    width: 234.83px;
    height: 46px;
    transform: scale(1.2);
  }

  form {
    display: flex;
    flex-direction: column;
    padding: 35px 50px;
    box-shadow: 0px 20px 25px #12325529;
    max-width: 700px;
    width: 100%;

    h2 {
      text-align: center;
      font: normal normal bold 25px/34px "Open Sans", sans-serif;
      letter-spacing: 0px;
      color: #000000;
      margin-bottom: 35px;
    }

    .avatarField {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 35px;
      .avatarWrapper {
        border-radius: 100%;
        border: 10px solid var(--primary-color);
        align-self: center;
        width: 225px;
        height: 225px;
        margin-bottom: 10px;
        cursor: pointer;
        .avatarContainer {
          width: 100%;
          height: 100%;
          align-self: center;
          border: 10px solid #ffffff;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          img {
            border-radius: 100%;
            width: 100%;
            height: 100%;
            transition: all 400ms ease-in-out;
          }

          input {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 100%;
            opacity: 0;
            cursor: pointer;
          }

          svg {
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            top: 50%;
            transform: translateY(-50%);
            display: none;
            transition: all 400ms ease-in-out;
          }
          &:hover {
            img {
              filter: brightness(80%);
            }
            svg {
              display: block;
            }
          }
        }
      }
      .error {
        color: red;
      }
    }

    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 15px;
      .field {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 20px;
        flex-grow: 1;

        label {
          margin-bottom: 10px;
        }

        input {
          height: 45px;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #95a5b2;
          border-radius: 5px;
          width: 100%;
          padding: 0px 15px;
        }

        .error {
          color: red;
        }
      }
    }

    button {
      margin: 10px 0px;
      width: 100%;
      height: 45px;
      background: var(--secondary-color) 0% 0% no-repeat padding-box;
      border-radius: 5px;
      border: none;
      cursor: pointer;
      font: normal normal normal 19px/26px "Open Sans", sans-serif;
      letter-spacing: 0px;
      color: #ffffff;
    }

    .redirect {
      font: normal normal normal 17px/24px "Open Sans", sans-serif;
      letter-spacing: 0px;
      color: var(--primary-color);
      text-decoration: underline;
      text-align: center;
      cursor: pointer;
    }
  }
}
