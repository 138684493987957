.container {
  display: flex;
  flex-direction: column;

  .title {
    font: normal normal normal 15px/20px "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #000000;
    margin-bottom: 12px;
  }

  form {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 20px;

    input {
      width: 125px;
      height: 31px;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #95a5b2;
      border-radius: 5px;
      padding: 0px 10px;
      font: normal normal normal 15px/20px "Open Sans", sans-serif;
      letter-spacing: 0px;
      color: #000000;
    }

    button {
      width: 105px;
      height: 31px;
      background: var(--secondary-color) 0% 0% no-repeat padding-box;
      border-radius: 5px;
      border: none;
      cursor: pointer;
      font: normal normal normal 13px/18px "Open Sans", sans-serif;
      letter-spacing: 0px;
      color: #ffffff;
      display: flex;
      align-items: center;
      img {
        margin: 0px 10px 0px 7px;
      }
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        /* add other styles as desired */
      }
    }
  }

  h4 {
    font: normal normal bold 15px/20px "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #000000;
    margin-bottom: 10px;
  }

  .list {
    display: flex;
    flex-direction: column;
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 386px;
      height: 55px;
      background: #f1f3f3 0% 0% no-repeat padding-box;
      padding: 0px 12px;

      .editField {
        width: 165px;
        height: 31px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #95a5b2;
        border-radius: 5px;
        padding: 0px 10px;
        font: normal normal normal 15px/20px "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: #000000;
        margin-left: 28px;
      }
      &:first-child {
        border-radius: 10px 10px 0px 0px;
      }
      p {
        font: normal normal normal 15px/20px "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: #000000;
      }
      .icons {
        display: flex;
        align-items: center;
        img {
          cursor: pointer;
        }
        .delete {
          margin-left: 18px;
        }
      }
    }
    li:nth-of-type(even) {
      background: #eaf0f2 0% 0% no-repeat padding-box;
    }
  }
}
