.container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 40px;

  .tab {
    width: 150px;
    height: 40px;
    background: #d5e1f2 0% 0% no-repeat padding-box;
    border-radius: 23px;
    display: flex;
    align-items: center;
    padding: 10px 10px;
    cursor: pointer;

    img {
      margin: 0px 10px 0px 10px;
    }

    p {
      font: normal normal normal 15px/20px "Open Sans", sans-serif;
      letter-spacing: 0px;
      color: #000000;
    }
  }
  .active {
    background: #aec5e5 0% 0% no-repeat padding-box;
    border: 2px solid var(--secondary-color);
  }
}
