.container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;

  h4 {
    font: normal normal bold 15px/20px "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #000000;
    margin-bottom: 20px;
  }

  .selectAll {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    input {
      margin-left: 10px;
      margin-bottom: -3px;
    }

    p {
      font: normal normal normal 15px/20px "Open Sans", sans-serif;
      letter-spacing: 0px;
      color: #000000;
    }
  }

  .parts {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;
    max-height: 440px;
    max-width: 410px;

    .part {
      width: 186px;
      height: 55px;
      background: #f1f3f3 0% 0% no-repeat padding-box;
      display: flex;
      align-items: center;
      padding: 0px 8px;

      input {
        margin-left: 10px;
        margin-bottom: -3px;
      }
      p {
        font: normal normal normal 15px/20px "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: #000000;
      }
    }

    li:nth-of-type(even) {
      background: #eaf0f2 0% 0% no-repeat padding-box;
    }

    li {
      &:first-child {
        border-radius: 10px 10px 0px 0px;
      }
      &:last-child {
        border-radius: 0px 0px 9px 9px;
      }
    }
  }
}
