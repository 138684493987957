.container {
  display: flex;
  flex-direction: column;

  .title {
    font: normal normal normal 15px/20px "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #000000;
    margin-bottom: 12px;
  }

  form {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 20px;

    input {
      width: 125px;
      height: 31px;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #95a5b2;
      border-radius: 5px;
      padding: 0px 10px;
      font: normal normal normal 15px/20px "Open Sans", sans-serif;
      letter-spacing: 0px;
      color: #000000;
    }

    button {
      width: 105px;
      height: 31px;
      background: var(--secondary-color) 0% 0% no-repeat padding-box;
      border-radius: 5px;
      border: none;
      cursor: pointer;
      font: normal normal normal 13px/18px "Open Sans", sans-serif;
      letter-spacing: 0px;
      color: #ffffff;
      display: flex;
      align-items: center;
      .spinner {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        /* add other styles as desired */
      }

      img {
        margin: 0px 10px 0px 14px;
      }
    }
  }

  h4 {
    font: normal normal bold 15px/20px "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #000000;
    margin-bottom: 20px;
  }

  .list {
    display: flex;
    flex-direction: column;
    .item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .editField {
        width: 165px;
        height: 31px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #95a5b2;
        border-radius: 5px;
        padding: 0px 10px;
        font: normal normal normal 15px/20px "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: #000000;
        margin-left: 28px;
      }
      .box {
        display: flex;
        align-items: center;
        width: 165px;
        height: 31px;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 5px;
        margin-left: 28px;
        cursor: pointer;

        span {
          font-size: 12px;
          color: var(--secondary-color);
          font-weight: 600;
          margin: 0px 5px;
        }
        p {
          font: normal normal normal 15px/20px "Open Sans", sans-serif;
          letter-spacing: 0px;
          color: #000000;
        }
      }

      .active {
        background: #d6fff9 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #08eecd;
        p {
          font-weight: bold;
        }
      }

      img {
        cursor: pointer;
      }
      .delete {
        margin-left: 18px;
      }
    }
  }
}
