.container {
  display: flex;
  flex-direction: column;
  padding: 15px 50px;

  h3 {
    font: normal normal bold 20px/27px "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #000000;
    margin-bottom: 20px;
  }

  .categories {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;
    height: 100%;
    max-height: 400px;
    max-width: 620px;
    margin-bottom: 20px;

    .cat {
      display: flex;
      align-items: center;
      gap: 10px;
      .category {
        display: flex;
        align-items: center;
        width: 142px;
        height: 31px;
        padding: 0px 5px;
        cursor: pointer;

        input {
          margin-left: 5px;
          margin-bottom: -3px;
        }

        p {
          font: normal normal normal 15px/20px "Open Sans", sans-serif;
          letter-spacing: 0px;
          color: #000000;
        }

        .removeIcon {
          width: 20px;
          margin-left: 5px;
        }
      }
      .activeCat {
        background: #d6fff9 0% 0% no-repeat padding-box;
        border: 1px solid #08eecd;
        border-radius: 5px;
        p {
          font-weight: bold;
        }
      }

      .unAssign {
        background: #d22b2b28 0% 0% no-repeat padding-box;
        border: 1px solid #d22b2b;
        border-radius: 5px;
        width: auto;
        padding: 0px 7px;

        p {
          font-size: 12px;
        }
      }
    }
  }

  .submitParts {
    width: 135px;
    height: 46px;
    background: #1a59da 0% 0% no-repeat padding-box;
    border-radius: 5px;
    border: none;
    font: normal normal normal 15px/20px "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #ffffff;
    cursor: pointer;
  }
}
